<template>
    <div :id="echartsId" class="echarts" :style="{ width: width, height: height }"></div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import resize from '../mixins/resize';
import bus from '@/utils/bus';

export default {
    name: 'Chart',
    mixins: [resize],
    props: {
        echartsId: {
            type: String,
            required: true
        },
        option: {
            type: Object
        },
        needJsonStr: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '40vw'
        },
        height: {
            type: String,
            default: '40vh'
        }
    },
    data() {
        return {
            myEchartId: null,
            clickCallback: null,
        }
    },
    watch: {
        option: {
            handler(val) {
                this.initEchart()
            },
            deep: true
        }
    },
    methods: {
        initEchart() {
            if (this.myEchartId !== null) {
                echarts.dispose(echarts.getInstanceById(this.myEchartId));
                this.myEchartId = null;
            }

            const myChart = echarts.init(document.getElementById(this.echartsId), null, {renderer: 'canvas'})
            myChart.showLoading();

            if(this.option){
                let opJson = this.option;
                if(this.needJsonStr){
                    const opStr = JSON.stringify(this.option, function(key, val) {
                        if (typeof val === 'function') {

                            return "!function!" + val + '';
                        }
                        return val;
                    })
                    opJson = JSON.parse(opStr,function(k,v){
                        if(!v) return v;

                        if(v.indexOf && v.indexOf('!function!') > -1){
                            return eval(`(function(){return ${v.replace("!function!","")} })()`)
                        }
                        return v;
                    });
                }
                myChart.setOption(opJson);
            }
            myChart.hideLoading();
            bus.$emit('echartInitFinish');

            if(this.clickCallback){
                myChart.on('click', this.clickCallback);
            }

            let i = 0, j = 0, siv = null;
            myChart.on('rendered', () => {
                i++;
                if (siv !== null) return;
                siv = setInterval(() => {
                    if (j < i) {
                        j = i
                    } else {
                        //"渲染完毕"
                        this.$emit('finished');
                        clearInterval(siv)
                    }
                }, 500)
            })

            this.myEchartId = myChart.id;
        },
        setOption() {
            if(!this.option) return;
            const myChart = echarts.getInstanceById(this.myEchartId);

            let opJson = this.option;
            if(this.needJsonStr){
                const opStr = JSON.stringify(this.option, function(key, val) {
                    if (typeof val === 'function') {

                        return "!function!" + val + '';
                    }
                    return val;
                })
                opJson = JSON.parse(opStr,function(k,v){
                    if(!v) return v;

                    if(v.indexOf && v.indexOf('!function!') > -1){
                        return eval(`(function(){return ${v.replace("!function!","")} })()`)
                    }
                    return v;
                });
            }
            myChart.setOption(opJson);
        },
        // 图表点击事件
        onChartClick(callback) {
            if (this.myEchartId === null) {
                setTimeout(() => {
                    this.onChartClick(callback)
                }, 1000);
                return;
            }

            const myChart = echarts.getInstanceById(this.myEchartId)

            this.clickCallback = function (params) {
                callback(params);
            }

            myChart.on('click', this.clickCallback)
        }
    },
    beforeDestroy() {
        if (this.myEchartId) {
            const myChart = echarts.getInstanceById(this.myEchartId)
            myChart.clear();
            myChart.dispose();
        }
        this.myEchartId = null;

        this.clickCallback = null;
    }
}
</script>
